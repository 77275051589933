import React from 'react';
import { BreadcrumbItem, UserInfo } from '@/typgins';
import { LinkItem } from './Links';

export interface LayoutContextProps {
  links?: LinkItem[];
  userInfo?: UserInfo;
  breadcrumbs?: BreadcrumbItem[];
}

export const LayoutContext = React.createContext<LayoutContextProps>({});
