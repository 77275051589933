import Head from 'next/head';
import React from 'react';
import { projectName } from '#/projectConfig';

export interface TitleProps {
  children?: string;
  hiddenWebSiteName?: boolean;
  suffix?: string;
}

const Title: React.FC<TitleProps> = ({
  children,
  hiddenWebSiteName,
  suffix = projectName,
}) => {
  return (
    <Head>
      <title>
        {children}
        {!hiddenWebSiteName && children && '-'}
        {!hiddenWebSiteName && suffix}
      </title>
    </Head>
  );
};

Title.defaultProps = {
  hiddenWebSiteName: false,
};

export default Title;
